/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* html {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  height: 100%;
} */
body {
  background-color: whitesmoke;
  height: 100vh;
  margin: 0;
}
#root {
  /* display: flex;
  flex-direction: column;
  min-height: 100%; */
  height: 100%;
  /* position: relative; */
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.vh-100{
  min-height: 100vh;
}
.dropdown-item.active {
  color: #fff;
  text-decoration: none;
  background-color: #464a4e;
}
.dropdown-item:not(:hover) {
  color: var(--bs-nav-link-color);
}
.dropdown-item:hover {
  color: var(--bs-navbar-hover-color);
}


[aria-current="page"] {
  background-color: #464a4e;
  border-radius: 10px;
}

[aria-selected="false"] {
  color: #fff;
  background-color: transparent !important;
}
[aria-selected="true"] {
  color: #fff !important;
}
.dropdown-menu.show {
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important
}
/* Style the <hr> element */

/* li > button.nav-link:active {
  background-color: darkgray;
} */

@media print {
  .ReactCollapse--collapse {
    height: auto !important;
  }
  body {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  .navbar {
    display: none;
  }
  @page { margin: 0;
    size: A3; }
}